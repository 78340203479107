<template>
  <v-card>
    <v-card-title>
      Создание учебной группы
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="group.name" :error-messages="errors.name" label="Название"/>
      <v-autocomplete v-if="!department_id" :readonly="this.department_id" v-model="group.department_id"
                      :clearable="!this.department_id"
                      :error-messages="errors.department_id"
                      :items="$store.state.dictionaries.departments"
                      aria-autocomplete="none"
                      item-text="name"
                      item-value="id"
                      label="Структурное подразделение"
                      no-data-text="Такого института у нас нет"
      />

    </v-card-text>
    <v-card-actions>
      <v-btn color="blue lighten-2" text @click="$emit('close')">Отменить</v-btn>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-2" text @click="save">Сохранить</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "CreateStudentGroupModal",
  props: ['department_id'],
  data() {
    return {
      group: {
        name: null,
        department_id: null
      },
      errors: {
        name: null,
        department_id: null
      },
      loading: false
    }
  },
  mounted() {
    if (this.department_id) {
      this.group.department_id = this.department_id;
    }
  },
  methods: {
    save() {
      this.loading = true;
      this.$store.dispatch('createStudentGroup', this.group).then((r) => {
        this.$emit('create', r);
        this.$emit('close');
        this.loading = false;
      }).catch((error) => {
        // eslint-disable-next-line no-console
        this.errors = error.response.data.errors;

      }).finally(() => {
        this.loading = false;
      })

    }
  }
}
</script>

<style scoped>

</style>
